<script setup lang="ts">
import type { OrganismsSidebarSlideProps } from './OrganismsSidebarSlide.props'

const props = defineProps<OrganismsSidebarSlideProps>()

const emit = defineEmits([
  'sidebar-closed',
  'sidebar-closed-after-leave',
  'action-button-click',
  'primary-click',
  'secondary-click',
])

const { isDialogOpen, closeDialog, closeAllDialogs } = useDialog(props.id)

const { headerSnackbars } = useHeaderSnackbars()

const transitionEnded = ref(false)
const { currentBrandTheme: brandTheme } = await useTheme()

const handleSideSlideClose = (forceStayOpen?: boolean) => {
  if (transitionEnded.value && !forceStayOpen) {
    transitionEnded.value = false
    props.closeEverySidebar ? closeAllDialogs() : closeDialog()
    emit('sidebar-closed')
  }
}

// close dialog when clicking outside
const handleClickOnAlphaLayer = () => {
  // also check for headerSnackbars.value.size, because closing a snackbar counts as an alpha layer click
  if (!props.disableClickToCloseOutside && !headerSnackbars.value.size) {
    handleSideSlideClose()
  }
}

// close dialog when pressing escape
onKeyStroke(
  'Escape',
  e => {
    e.preventDefault()
    handleSideSlideClose(props.forceOpen)
  },
  { dedupe: false }
)

const { breakpoint } = useProjectBreakpoint()

// close dialog when breakpoint changes to mobile
watch(breakpoint.smaller('md'), isMobile => {
  if (isMobile && props.closeAndHideOnMobile) {
    handleSideSlideClose()
  }
})

const { isEmporioArmaniWebsite, isEa7Website } = useWebsite()

const alphaLayerClass = computed(() => {
  const isBrandWebsite = isEmporioArmaniWebsite.value || isEa7Website.value
  return isBrandWebsite ? 'bg-primitives-black/60' : 'bg-primitives-white/80'
})

const changeSidebarSlideAttribute = (status: boolean) => {
  if (import.meta.client && document.body) {
    if (status) {
      document.body.setAttribute('data-active-sidebar-slide', 'true')
    } else {
      document.body.removeAttribute('data-active-sidebar-slide')
    }
  }
}

watch(
  () => isDialogOpen.value,
  (newStatus, oldStatus) => {
    if (newStatus !== oldStatus) changeSidebarSlideAttribute(newStatus)
  }
)

onMounted(() => {
  if (isDialogOpen.value) changeSidebarSlideAttribute(isDialogOpen.value)
})
</script>

<template>
  <div>
    <HeadlessTransitionRoot
      :show="isDialogOpen"
      as="template"
      :class="{ 'pointer-events-none': !transitionEnded }"
      appear
    >
      <HeadlessDialog @close="handleClickOnAlphaLayer">
        <HeadlessTransitionChild
          as="template"
          class="opacity-0"
          enter="transition-opacity ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
          @after-enter="transitionEnded = true"
        >
          <div
            class="alpha_layer fixed inset-0 z-[99]"
            :class="{
              'backdrop-blur-md': blurBackground,
              [alphaLayerClass]: !isAlphaLayerTransparent,
            }"
          />
        </HeadlessTransitionChild>

        <HeadlessTransitionChild
          as="template"
          class="translate-x-full"
          enter="transition ease-out duration-500 transform"
          enter-from="translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in duration-500 transform"
          leave-from="translate-x-0"
          leave-to="translate-x-full"
          @after-leave="emit('sidebar-closed-after-leave')"
        >
          <div
            :id="`sidebar-${id}`"
            class="grid-container fixed inset-0 z-[99]"
            @wheel.stop="''"
          >
            <div
              :id
              class="lg-custom:col-span-4 col-span-full !col-end-[-1] md:col-span-6"
              :class="[
                forceGlobalTheme ? 'theme-global' : `theme-${brandTheme}`,
              ]"
            >
              <HeadlessDialogPanel
                class="border-primitives-grey-100 bg-background-secondary text-text-primary flex h-dvh min-h-[101dvh] flex-col border-l"
              >
                <OrganismsSidebarHeader
                  v-if="headerProps"
                  class="sticky top-0"
                  v-bind="headerProps"
                  @back-button-click="handleSideSlideClose"
                  @close-button-click="handleSideSlideClose"
                  @action-button-click="emit('action-button-click')"
                />

                <div
                  class="flex-1 overflow-y-auto"
                  :class="{
                    'positive-padding': !removeHorizontalPaddingBody,
                  }"
                >
                  <slot name="body" />
                </div>

                <slot name="footer">
                  <OrganismsSidebarFooter
                    v-if="footerProps"
                    class="sticky bottom-0 left-0 right-0"
                    v-bind="footerProps"
                    :is-primary-disabled="
                      selectedExtOutOfStock ||
                      selectedDefaultOrOneSizeOutOfStockCl
                    "
                    :is-secondary-disabled="
                      selectedExtOutOfStock ||
                      selectedDefaultOrOneSizeOutOfStockCl
                    "
                    @primary-click="emit('primary-click')"
                    @secondary-click="emit('secondary-click')"
                  />
                </slot>
              </HeadlessDialogPanel>
            </div>
          </div>
        </HeadlessTransitionChild>
      </HeadlessDialog>
    </HeadlessTransitionRoot>
  </div>
</template>
